<template>
  <on-modal v-if="showModalStatus" @close="hideModal" headerBg="bg-gray-300">
    <template v-slot:header>
      <div class="text-gray-500 text-xl font-bold pb-4">
        {{ title }}
      </div>
    </template>
    <template v-slot:body>
      <ul class="flex gap-4 flex-wrap">
        <li
          v-for="image in imageList"
          :key="image.id"
          class="relative cursor-pointer"
          @click="selectImage(image)"
        >
          <div
            v-if="image.selected"
            class="absolute z-10 top-0 right-0 bg-primary-400 text-white rounded-full h-8 w-8 flex items-center justify-center"
          >
            <i class="mdi mdi-check "></i>
          </div>

          <v-img
            :src="`${ASSET_DIR}thumb-${image.title}`"
            class="object-cover w-32 h-32 rounded-md"
          ></v-img>
        </li>
      </ul>
      <div class="w-full flex justify-center items-center mt-5">
        <on-button
          :buttonClasses="['text-primary-500']"
          @click="loadOtherImages"
        >
          Carica altre immagini
        </on-button>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-3 gap-4 pt-3">
        <on-button
          :buttonClasses="[
            'text-white',
            'bg-green-500',
            'col-start-2',
            'col-span-1'
          ]"
          @click="confirmSelection"
        >
          Aggiungi selezionate
        </on-button>
        <on-button
          :buttonClasses="['text-white', 'bg-gray-300']"
          @click="hideModal"
        >
          Annulla
        </on-button>
      </div>
    </template>
  </on-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import OnModal from '@/_components/_ui/OnModal.vue'
import OnButton from '@/_components/_ui/OnButton.vue'
export default {
  components: {
    OnModal,
    OnButton
  },
  name: 'MediaLibraryModal',
  props: {
    title: {
      type: String,
      default: 'Seleziona le immagini dalla libreria'
    },
    excludedIds: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({ showModalStatus: ['media/showModalStatus'] })
  },
  data() {
    return {
      selectedImages: [],
      ASSET_DIR: process.env.ASSETS_DIR,
      imageList: [],
      nextToken: null
    }
  },
  methods: {
    async loadOtherImages() {
      let images = await this.$store.dispatch('media/list', {
        nextToken: this.nextToken,
        limit: 20
      })
      let newImages = images.items.map(el => ({ ...el, selected: false }))
      this.imageList = [...this.imageList, ...newImages]
      this.nextToken = images.nextToken
    },
    selectImage(image) {
      try {
        let itemIdx = this.imageList.findIndex(item => item.id === image.id)
        this.imageList[itemIdx].selected = !this.imageList[itemIdx].selected

        let itemInIdx = this.selectedImages.findIndex(item => item === image.id)
        if (itemInIdx === -1) {
          this.selectedImages.push(image.id)
        } else {
          this.selectedImages.splice(itemInIdx, 1)
        }
        console.log(
          '%cIMMAGINI SELEZIONATE',
          'color:green; font-size:20px',
          this.selectedImages
        )
      } catch (error) {
        console.log('Error while selecting new image ', error)
      }
    },
    confirmSelection() {
      this.$emit('confirmSelection', this.selectedImages)
      this.hideModal()
    },
    hideModal() {
      console.log('CHIUSURA MODALE')
      this.$store.dispatch('media/toggleModalStatus', false)
    }
  },
  mounted() {
    console.log('MODALEEE')
  },
  async created() {
    let filter = { id: { ne: '' } }
    if (this.excludedIds.length > 0) {
      let internalField = this.excludedIds.map(el => ({ id: { ne: el.id } }))

      let filterParams = internalField.join(', ')
      filter = { and: filterParams }
    }

    let images = await this.$store.dispatch('media/list', {
      filter: filter,
      nextToken: this.nextToken,
      limit: 20
    })
    this.$store.dispatch('media/toggleModalStatus', true)
    this.imageList = images.items.map(el => ({ ...el, selected: false }))
    this.nextToken = images.nextToken
  }
}
</script>

<style></style>
