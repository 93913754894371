<template>
  <LeftMenuPage
    :menu="[]"
    :titleMenu="'Properties'"
    titleContent="'Tutte le strutture'"
  >
    <ToolBar>
      <template v-slot:title>
        <span>{{ pageTitle }}</span>
      </template>
      <template v-slot:actions>
        <label
          for="create-btn"
          class="relative btn-tools-with-icon-disabled whitespace-nowrap"
          :class="{
            'btn-tools-with-icon': $store.getters['auth/isAdmin']
          }"
        >
          <router-link to="/properties/list" class="flex items-center gap-4"
            ><i class="mdi mdi-chevron-left text-lg"></i> Tutte le
            strutture</router-link
          >
        </label>
        <input type="checkbox" class="hidden" id="back-btn" />
      </template>
    </ToolBar>
    <div class="container" v-if="!loading">
      <v-tabs v-model="activeTab" centered class="bg-white">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#structure">{{ structureTitle }}</v-tab>
        <v-tab v-if="isEdit" href="#booking">prenotazioni</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item value="structure">
          <div
            class="fixed overscroll-y-auto mt-14 overflow-y-auto left-0 md:left-64 right-0 top-24 bottom-0 py-5"
          >
            <div data-app>
              <tags-modal
                v-model="showTagModal"
                @close="closeTagModal"
                @added="addTagToForm"
                :structure="formData"
              ></tags-modal>
              <media-library-modal
                v-if="showModalStatus"
                @confirmSelection="addImagesToProperty"
              ></media-library-modal>
              <on-form
                :title="structureTitle"
                :subtitle="structureSubtitle"
                class="w-full"
              >
                <template v-slot:content>
                  <div
                    class="grid grid-cols-7 gap-4 align-baseline items-baseline"
                  >
                    <div class="col-span-2">
                      <h1>Tipo</h1>
                      <p class="text-gray-500 text-xs">
                        Slezionare la tipologia di struttura tra: Appartamento,
                        Hotel o Selling structure
                      </p>
                    </div>
                    <div class="col-span-5">
                      <v-select
                        v-model="formData.type"
                        :items="structTypes"
                        :rules="[v => !!v || 'Item is required']"
                        label="Tipo"
                        required
                      ></v-select>
                    </div>
                    <div class="col-span-5 col-start-3 flex">
                      <v-container fluid>
                        <v-row dense>
                          <v-col>
                            <v-checkbox
                              v-model="formData.highlight"
                              label="Highlight"
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col>
                            <v-select
                              label="Lingua"
                              v-model="formData.locale"
                              :items="locales"
                              :disabled="!!formData.id"
                            ></v-select>
                          </v-col>
                          <v-col>
                            <v-select
                              v-model="formData.status"
                              :items="structureStatus"
                              :disabled="!isAdmin"
                              label="Status"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                    <div class="col-span-2">Titolo</div>
                    <div class="col-span-5">
                      <v-text-field
                        :counter="30"
                        label="Titolo"
                        v-model="formData.title"
                        :rules="[v => !!v || 'Item is required']"
                        required
                      ></v-text-field>
                    </div>
                    <div class="col-span-2">Slug</div>
                    <div class="col-span-5">
                      <v-text-field
                        :counter="30"
                        disabled
                        label="Slug"
                        :value="slug"
                      ></v-text-field>
                    </div>

                    <div class="col-span-2"><h1>In Breve</h1></div>
                    <div class="col-span-5">
                      <v-textarea
                        label="In Breve"
                        v-model="formData.excerpt"
                        rows="2"
                        counter="300"
                        class=""
                      ></v-textarea>
                    </div>
                    <div class="col-span-2">Descrizione</div>
                    <div class="col-span-5">
                      <quill-editor
                        v-model="formData.description"
                        :options="editorOptions"
                      ></quill-editor>
                    </div>

                    <div class="col-span-2">Location</div>
                    <div class="col-span-5">
                      <google-maps
                        v-if="!loading"
                        :mapConfig="mapConfig"
                        showSearch
                        @addMarker="addMarker"
                        :markerList="markerList"
                      >
                        <google-map-marker
                          slot-scope="{ google, map }"
                          :google="google"
                          :map="map"
                          :marker="firstMarker"
                        ></google-map-marker>
                      </google-maps>
                      <google-maps
                        v-else
                        :mapConfig="mapConfig"
                        showSearch
                        @addMarker="addMarker"
                      >
                        <google-map-marker
                          slot-scope="{ google, map }"
                          :google="google"
                          :map="map"
                          :marker="firstMarker"
                        ></google-map-marker>
                      </google-maps>
                    </div>

                    <div class="col-span-2">Tags</div>
                    <div class="col-span-5">
                      <div class="w-full">
                        <ul class="my-4 px-0 mx-0" v-if="!!formData.tags.items">
                          <v-chip
                            close
                            v-for="(item, i) in formData.tags.items"
                            :key="i"
                            tag="li"
                            @click:close="removeTag(item.id)"
                            class="mr-4 mb-2"
                            >{{ item.tag.label }}</v-chip
                          >
                        </ul>
                        <ul class="my-4 px-0 mx-0" v-if="!!ghostTags">
                          <v-chip
                            close
                            v-for="(item, i) in ghostTags"
                            :key="i"
                            tag="li"
                            @click:close="removeGhostTag(item.id)"
                            class="mr-4 mb-2"
                            >{{ item.label }}</v-chip
                          >
                        </ul>

                        <v-divider></v-divider>
                        <h2 class="text-xs text-gray-400 mt-4">Tag recenti</h2>
                        <ul class="my-4 px-0 mx-0">
                          <v-chip
                            v-for="(item, i) in tagsData"
                            :key="i"
                            tag="li"
                            @click="addTagToForm(item)"
                            class="mr-4 mb-2"
                            >{{ item.label }}</v-chip
                          >
                          <v-chip
                            tag="li"
                            class="mr-4 mb-2"
                            color="primary"
                            outlined
                            @click="addNewTag"
                            ><v-icon>add</v-icon> Nuovo Tag</v-chip
                          >
                        </ul>
                      </div>
                    </div>
                    <template v-if="!!formData.id">
                      <div class="col-span-2">Immagini</div>
                      <div class="col-span-5">
                        <div class="flex gap-5 items-center">
                          <v-file-input
                            label="immagini"
                            accept="image/png, image/jpeg, image/bmp"
                            truncate-length="15"
                            multiple
                            small-chips
                            :loading="filesLoading"
                            @change="fileInputOnChange"
                          ></v-file-input>
                          <v-btn @click="showImageModal"
                            >libreria immagini</v-btn
                          >
                        </div>

                        <div class="grid grid-cols-5 gap-4">
                          <v-card
                            v-for="(image, i) in formData.images.items"
                            :key="i"
                            max-height="150"
                          >
                            <v-img
                              :src="`${ASSET_DIR}thumb-${image.image.title}`"
                              class="w-full h-full object-cover"
                            ></v-img>
                            <v-btn
                              fab
                              icon
                              absolute
                              class="top-0 right-0 mx-auto"
                              small
                              color="error"
                              @click="deleteImage(image.id)"
                              ><v-icon>close</v-icon></v-btn
                            >
                          </v-card>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-slot:action>
                  <div class="col-span-5 col-start-3">
                    <v-btn
                      color="text-primary-500"
                      outlined
                      @click="submitForm"
                      :disabled="$v.$invalid"
                      :loading="$store.state.structures.loading"
                      >{{ buttonText }}</v-btn
                    >
                  </div>
                </template>
              </on-form>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item value="booking">
          <h1 class="text-3xl my-12">
            Prenotazioni
            <h1 class=" inline-block font-bold" v-if="!!formData && isEdit">
              {{ formData.title }}
            </h1>
          </h1>
          <booking-property
            :events="formData.bookings.items"
            v-if="!!formData.bookings"
          ></booking-property>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </LeftMenuPage>
</template>

<script>
import BookingProperty from './BookingProperty.vue'
import OnForm from '@/_components/_ui/OnForm.vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Storage from '@aws-amplify/storage'
import slugify from 'slugify'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import TagsModal from '../Tags/TagsModal.vue'
import { mapGetters, mapState } from 'vuex'
import { v4 as uuid } from 'uuid'
import GoogleMaps from '@/_components/GoogleMaps.vue'
import GoogleMapMarker from '@/_components/GoogleMapMarker.vue'
import MediaLibraryModal from '@/entities/Media/MediaLibraryModal.vue'

export default {
  name: 'PropertyEdit',
  components: {
    BookingProperty,
    OnForm,
    LeftMenuPage: () => import('@/_templates/LeftMenuPage.vue'),
    ToolBar: () => import('@/_components/ToolBar.vue'),
    quillEditor,
    GoogleMaps,
    GoogleMapMarker,
    TagsModal,
    MediaLibraryModal
  },
  mixins: [validationMixin],
  data() {
    return {
      isEdit: false,
      pageTitle: '',
      formData: {
        id: null,
        title: '',
        description: '',
        excerpt: '',
        type: '',
        lat: '',
        lng: '',
        highlight: '',
        address: '',
        tags: [],
        images: []
      },
      loading: true,
      activeTab: 0,
      buttonText: '',
      ASSET_DIR: process.env.ASSETS_DIR,
      ghostTags: [],
      allTags: [],
      locales: [
        { text: 'italiano', value: 'it' },
        { text: 'english', value: 'en' }
      ],
      statuses: ['published', 'unpublished', 'new'],
      filesLoading: false,
      showTagModal: false,
      structTypes: ['apartment', 'hotel', 'selling'],
      editorOptions: {
        modules: {
          toolbar: [
            [{ header: [false, 1] }],
            ['bold', 'italic', 'underline'],
            [{ header: 1 }, { header: 2 }, { header: 3 }]
          ]
        }
      }
    }
  },
  validations: {
    formData: {
      title: {
        required
      },
      type: {
        required
      }
    }
  },
  computed: {
    structureTitle() {
      return this.$route.params.id
        ? 'Modifica la struttura'
        : 'Crea la struttura'
    },
    structureSubtitle() {
      return this.$route.params.id
        ? ''
        : 'Segui le istruzioni per creare la struttura'
    },
    ...mapGetters({ tagsData: 'tags/getData' }),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters({ showModalStatus: 'media/showModalStatus' }),
    ...mapState('structures', ['structureStatus']),
    mapConfig() {
      return {
        // ...mapSettings,
        center: this.firstMarker,
        zoom: 13
      }
    },
    firstMarker() {
      return {
        lat: this.formData.lat || 41.8931,
        lng: this.formData.lng || 12.4828
      }
    },
    markerList() {
      let markerList = this.formData.id
        ? { lat: this.formData.lat, lng: this.formData.lng }
        : null
      console.log('MARKER LIST', markerList)
      return markerList
    },
    // categorySlug: {
    //   get() {
    //     return this.form.categorySlug;
    //   },
    //   set() {
    //     console.log("imposto category slug", this.form.type);
    //     this.form.categorySlug = this.getSlug(this.form.type);
    //   }
    // },
    slug: {
      get() {
        return this.getSlug(this.formData.title) //this.formData.pageSlug;
      },
      set() {
        this.formData.pageSlug = this.getSlug(this.formData.title)
      }
    }
  },
  created: async function() {
    try {
      await this.$store.dispatch('tags/list')
      this.formData.tags = { items: [], next: null }
      if (this.$route.params.id) {
        let struct = await this.loadStructure(this.$route.params.id)
        console.log('Struct ', struct)

        this.pageTitle = 'Modifica'
        this.buttonText = 'Salva modifiche'
        this.formData = struct
        this.isEdit = true
        this.loading = false
      } else {
        this.buttonText = 'Crea'
        this.pageTitle = 'Crea'
        this.loading = false
      }
    } catch (error) {
      console.log('Errore caricamento struttura', error)
    }
  },
  methods: {
    showImageModal() {
      this.$store.dispatch('media/toggleModalStatus', true)
    },
    submitForm() {
      try {
        if (this.formData.id !== null) {
          this.updateStructure()
        } else {
          this.createStructure()
        }
      } catch (e) {
        console.log('Errore salvataggio form: ', e)
      }
    },
    createStructure: async function() {
      try {
        let data = this.formData
        var newStruct = await this.$store.dispatch('structures/create', {
          type: data.type,
          title: data.title,
          description: data.description,
          excerpt: data.excerpt,
          locale: data.locale,
          lat: parseFloat(data.lat),
          lng: parseFloat(data.lng),
          highlight: !!data.highlight,
          address: data.address,
          status: data.status || 'unpublished'
          //"booked": false,
        })

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!data.ghostTags) {
          await Promise.all(
            data.ghostTags.map(
              async g =>
                await this.$store.dispatch('tags/createTagStructure', {
                  tagStructuresStructureId: newStruct.data.createStructure.id,
                  tagStructuresTagId: g.id
                })
            )
          )
        }
        this.$router.push({ name: 'properties' })
      } catch (error) {
        console.log('Errore durante la creazione della struttura', error)
      }
    },
    async updateStructure() {
      let data = this.formData
      console.log('Modifico la struttura ', data)
      await this.$store.dispatch('structures/update', {
        updated: {
          id: data.id,
          title: data.title,
          description: data.description,
          excerpt: data.excerpt,
          highlight: data.highlight,
          lat: data.lat,
          lng: data.lng,
          address: data.address,
          status: data.status,
          type: data.type
        }
      })
      console.log('Modifico i tag', data)
      this.$router.push({ name: 'properties' })
    },
    loadStructure(id) {
      console.log('%cUPDATE STRUCTURE', 'color:tomato;font-size:1.2rem', id)
      return this.$store.dispatch('structures/getStructure', id)
    },
    getSlug(s) {
      return slugify(s, {
        lower: true,
        locale: 'it',
        remove: /[*+~.()'"!:@]/g
      })
    },
    addMarker(e) {
      console.log('Add marker ', e)
      if (e.marker[0].position != undefined) {
        this.formData.lat = e.marker[0].position.lat()
        this.formData.lng = e.marker[0].position.lng()
        this.formData.address = e.address
      }
    },
    // addStructure() {
    //   this.$refs.property.validate()

    //   //this.form.description = this.md.turndown(this.form.description)
    //   if (!this.formData.id) {
    //     this.formData.ghostTags = [...this.ghostTags]
    //   }
    //   this.formData.categorySlug = this.getSlug(this.formData.type)
    //   this.formData.pageSlug = this.slug
    //   //this.formData.address = this.address;
    //   //this.formData.categorySlug = this.getSlug(this.formData.type)
    //   console.log('Before submit form', this.formData)
    //   this.$emit('submit', this.formData)
    // },
    async removeTag(id) {
      await this.$store.dispatch('tags/deleteTagStructure', id)
      let idx = this.formData.tags.items.findIndex(t => t.id == id)
      this.formData.tags.items.splice(idx, 1)
    },
    removeGhostTag(id) {
      let idx = this.ghostTags.findIndex(t => t.id == id)
      this.ghostTags.splice(idx, 1)
    },

    async addTagToForm(tag) {
      if (this.formData.id) {
        if (!this.formData.tags.items.some(t => t.tag.id == tag.id)) {
          let newData = await this.$store.dispatch('tags/createTagStructure', {
            tagStructuresStructureId: this.formData.id,
            tagStructuresTagId: tag.id
          })
          this.formData.tags.items.push(newData.data.createTagStructures)
          console.log('✅')
        } else {
          console.log('❌')
        }
      } else {
        if (!this.ghostTags.some(g => g.id == tag.id)) {
          this.ghostTags.push(tag)
        }
      }
    },
    addNewTag() {
      this.showTagModal = true
    },
    async deleteImage(id) {
      try {
        await this.$store.dispatch('media/deleteImageStructures', id)
        let idx = this.formData.images.items.findIndex(i => i.id == id)
        this.formData.images.items.splice(idx, 1)
      } catch (error) {
        console.log('Errore di cancellazione ', error)
      }
    },
    async uploadFileToS3(file) {
      //a function that returns a promise
      let uu = uuid()
      let ext = file.name.split('.')[1]
      let newName = `${uu}.${ext}`
      console.log('Before put', newName, file)
      const { key } = await Storage.put(newName, file, {
        contentType: file.type
      })

      console.log('S3 Object key', key)
      let img = await this.$store.dispatch('media/create', {
        title: key
      })
      console.log('%cCreo le connessioni ', 'color:cyan;font-size:1.2rem', img)
      this.addImageToProperty(img.data.createImage.id)
      // //TODO: add key to a state variable to use it later

      return Promise.resolve(key)
    },
    async addImageToProperty(imgId) {
      let newData = await this.$store.dispatch('media/createImageStructures', {
        imageStructuresImageId: imgId,
        imageStructuresStructureId: this.formData.id
      })
      this.formData.images.items.push(newData.data.createImageStructures)
    },
    // prepareUpload: async function (file) {
    //   return this.uploadFileToS3(file)
    // },
    fileInputOnChange: async function(files) {
      console.log(files)
      this.filesLoading = true
      try {
        let imgs = await Promise.all(
          files.map(async file => await this.uploadFileToS3(file))
        )
        console.log('all done!', imgs)
        // Create the connection between structures and keys
      } catch (error) {
        console.error('Errore durante il caricamento ', error)
      } finally {
        this.filesLoading = false
      }
      // let files = Array.from(e);

      // TODO: take (from the state variable) all the keys uploaded and,
      // once the structure has been created, update the structureID of
      // each image, to create the proper connection
    },
    closeTagModal() {
      // this.getAllTags();
      this.showTagModal = false
      console.log('Close tag modal', this.allTags)
    },
    async addImagesToProperty(imageList) {
      console.log('LISTA IMMAGINI DA CARICARE', imageList)
      imageList.forEach(element => {
        this.addImageToProperty(element)
      })
    }
  },
  async mounted() {}
}
</script>

<style></style>
